import { ControllerParams } from 'yoshi-flow-editor-runtime';

import { PROFILE_ORIGIN } from '../constants/bi';
import { Handler, HTTPMethod, InjectedGlobalSettings } from '../types';
import request, { requestText } from './request';

type ViewMode =
  ControllerParams['controllerConfig']['wixCodeApi']['window']['viewMode'];

class SettingsService {
  constructor(
    private readonly componentId: string,
    private readonly baseUrl: string,
    private readonly getInstance: Handler<string>,
  ) {}

  getGlobalSettings(viewMode: ViewMode) {
    const url = `${this.baseUrl}/settings/global`;
    const queryParams = this.getQueryParams(viewMode);
    return request<InjectedGlobalSettings>(`${url}?${queryParams}`, {
      method: HTTPMethod.Get,
      headers: this.getHeaders(),
    });
  }

  patchGlobalSettings(globalSettings: Partial<InjectedGlobalSettings>) {
    const url = `${this.baseUrl}/settings/merge/global`;
    const queryParams = this.getQueryParams('Editor');

    return requestText(`${url}?${queryParams}`, {
      method: HTTPMethod.Put,
      headers: this.getHeaders(),
      body: JSON.stringify(globalSettings),
    });
  }

  private getQueryParams(viewMode: ViewMode) {
    const viewModeLowerCased = viewMode.toLocaleLowerCase();
    return `compId=${this.componentId}&appComponent=${PROFILE_ORIGIN}&viewMode=${viewModeLowerCased}`;
  }

  private getHeaders() {
    return {
      'Content-Type': 'application/json',
      instance: this.getInstance(),
    };
  }
}

export default SettingsService;
