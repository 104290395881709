export const membersAreaAppBaseUrl = 'https://members.wixapps.net/members-area';
export const newMembersAreaAppBaseUrl =
  'https://members.wixapps.net/_api/members-area';

export const profileCardTpaBaseUrl =
  'https://members.wixapps.net/profile-card-tpa';

export const membersApiRelativeBaseUrl = '/members-area/_api';
export const newMembersApiRelativeBaseUrl = '/_api/members-area/_api';

export const membersApiAbsoluteBaseUrl =
  'https://members.wixapps.net/members-area/_api';
export const newMembersApiAbsoluteBaseUrl =
  'https://members.wixapps.net/_api/members-area/_api';

export const badgesApiRelativeBaseUrl = '/_api/members-badges-server/v1';
export const badgesApiAbsoluteBaseUrl =
  'https://www.wix.com/_api/members-badges-server/v1';

export const customFieldsApiRelativeBaseUrl = '/_api/members/v1/custom-fields';

export const memberBlockMemberServiceBaseUrl = '/_api/members/';
