import { BLOCKED_MEMBER_ID } from '../../../constants/common';
import PublicMemberBuilder from '../../../services/public-member-builder';
import { getSetViewedMemberAction } from '../../actions';
import {
  BIEvent,
  BlockActions,
  IFrameEvent,
  PublicMember,
  StoreState,
  ThunkExtra,
  ThunkWithArgs,
} from '../../../types';
import { openModalWithCallback } from '../../../services/modal';
import { RoleId } from '@wix/members-domain-ts';
import { emitBIEventWithPayload } from '../../../services/bi-event';
import { PROFILE_ORIGIN } from '../../../constants/bi';
import { Applications } from '../../../services/public-api-store';

const getBlockedMember = (): PublicMember => {
  return new PublicMemberBuilder().withUid(BLOCKED_MEMBER_ID).build();
};

const emitBlockConfirmedBiEvent = (
  state: StoreState,
  extra: ThunkExtra,
  action_type: BlockActions,
) => {
  const { uid } = state.users.viewed;
  emitBIEventWithPayload({
    biEvent: BIEvent.RoleActionClicked,
    payload: {
      action_type,
      member_chosen: uid,
      formOrigin: PROFILE_ORIGIN,
      site_member_id: state.users.current?.uid,
    },
    extra,
    state,
  });
};

export const memberBlockMember: ThunkWithArgs<RoleId> =
  (roleId) => async (dispatch, getState, extra) => {
    const {
      compId,
      wixCodeApi,
      platformAPIs,
      experiments,
      dataSyncService,
      getPublicAPI,
    } = extra;

    const state = getState();
    const { viewed } = state.users;
    const { uid } = viewed;
    const membersAreaAPI = await getPublicAPI(Applications.MembersArea);

    const onConfirm = async () => {
      const { blockMemberService } = extra;
      await blockMemberService.blockMember(uid);
      emitBlockConfirmedBiEvent(state, extra, 'block_confirm');
      dataSyncService.addIFrameEvent(IFrameEvent.SetMemberAsBlocked);
      if (membersAreaAPI?.clearMenus) {
        await membersAreaAPI.clearMenus();
      }
      dispatch(getSetViewedMemberAction(getBlockedMember()));
    };

    openModalWithCallback({
      compId,
      modalType: roleId,
      payload: { memberName: viewed.name },
      platformAPIs,
      wixCodeApi,
      experiments,
      onConfirm,
    });
  };
