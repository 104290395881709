import { IWixStyleParams } from 'yoshi-flow-editor-runtime/tpa-settings';

import {
  BIEvent,
  Experiments,
  Handler,
  InjectedHandlers,
  MetaData,
  Nullable,
} from '../types';
import {
  ControllerConfig,
  RouterData,
  RouterPublicData,
  AppParams,
  FlowAPI,
  WixCodeApi,
  WixExperiments,
} from '../types/controller';
import {
  badgesApiAbsoluteBaseUrl,
  badgesApiRelativeBaseUrl,
  membersApiAbsoluteBaseUrl,
  membersApiRelativeBaseUrl,
  newMembersApiAbsoluteBaseUrl,
  newMembersApiRelativeBaseUrl,
} from '../constants/urls';
import { PROFILE_ORIGIN } from '../constants/bi';
import { aboutAppDefinitionId } from '../constants/app-definition-id';
import { Store } from '../store';
import { SetSitePresetsPayload } from '../store/slices/global-settings-slice';
import {
  getSetBadgeListPayload,
  getSetInstalledAppsAction,
  getSetIsSocialChatAction,
  getSetRolesMapAction,
  getSetUsersAction,
  getSetGlobalSettingsAction,
  getSetComponentSettingsAction,
  getSetSitePresetsAction,
} from '../store/actions';
import {
  getIsOwnProfile,
  isMemberInCommunity,
  isInPage,
} from '../store/selectors';
import BadgesService from './badges-service';
import MediaService from './media-service';
import MembersService from './members-service';
import InitialDataFetchService from './initial-data-fetch-service';
import SettingsService from './settings-service';
import { BILogger } from './bi-logger';
import { emitBIEventWithPayload } from './bi-event';
import BlockMemberService from './block-member-service';

export interface Services {
  membersService: MembersService;
  badgesService: BadgesService;
  mediaService: MediaService;
  settingsService: SettingsService;
  blockMemberService: BlockMemberService;
}

interface InitPropsOptions {
  currentMemberId: Nullable<string>;
  viewedMemberId: Nullable<string>;
  store: Store;
  initialDataFetchService: InitialDataFetchService;
}

interface EmitAppLoadedBIEventOptions {
  widgetId: string;
  store: Store;
  metaData: Nullable<MetaData>;
  biLogger: Nullable<BILogger>;
  flowAPI: FlowAPI;
}

interface OpenProfilePreviewNotificationOptions {
  store: Store;
  flowAPI: FlowAPI;
  experiments: WixExperiments;
  handlers: Pick<InjectedHandlers, 'openPrivateProfilePreviewNotification'>;
}

export const getMetaData = (getInstance: () => string) => {
  const instance = getInstance();
  if (!instance) {
    return null;
  }

  try {
    const [, base64Data] = instance.split('.', 2);
    const metaData = JSON.parse(atob(base64Data)) as MetaData;
    return metaData;
  } catch {
    return null;
  }
};

export const getMetaSiteId = (
  controllerConfig: ControllerConfig,
  metaData: Nullable<MetaData>,
) => {
  const { bi } = controllerConfig.platformAPIs;
  if (bi?.metaSiteId) {
    return bi.metaSiteId;
  }

  return metaData?.metaSiteId ?? null;
};

const getViewedMemberIdFromRouterData = (routerData?: RouterData) => {
  if (!routerData) {
    return null;
  }

  const { memberData, pageData, publicData } = routerData;
  const viewedMemberIdFromMemberData = memberData?.memberContactId;
  const viewedMemberIdFromPageData = pageData?.memberData?.memberContactId;
  const viewedMemberIdFromPublicData = publicData?.viewedUser?.id;
  const viewedMemberId =
    viewedMemberIdFromMemberData ??
    viewedMemberIdFromPageData ??
    viewedMemberIdFromPublicData;

  return viewedMemberId ?? null;
};

const getViewedMemberIdFromPublicRouterData = (wixCodeApi: WixCodeApi) => {
  const routerData =
    wixCodeApi.window.getRouterPublicData?.<RouterPublicData>();
  const viewedUser = routerData?.viewedUser;

  return viewedUser?.id ?? null;
};

export const getCurrentMemberId = (
  wixCodeApi: WixCodeApi,
  metaData: Nullable<MetaData>,
) => {
  const currentUser = wixCodeApi.user.currentUser;
  const currentUserId = currentUser.loggedIn ? currentUser.id : null;
  return metaData?.siteMemberId ?? currentUserId ?? null;
};

export const getViewedMemberId = (
  { appParams, wixCodeApi }: ControllerConfig,
  metaData: Nullable<MetaData>,
) => {
  const { routerReturnedData: routerData } = appParams as AppParams;
  const currentMemberId = getCurrentMemberId(wixCodeApi, metaData);
  const routerDataViewedMemberId = getViewedMemberIdFromRouterData(routerData);
  const publicRouterDataViewedMemberId =
    getViewedMemberIdFromPublicRouterData(wixCodeApi);

  return (
    routerDataViewedMemberId ??
    publicRouterDataViewedMemberId ??
    currentMemberId ??
    null
  );
};

export const getInstanceFactory = ({
  appParams,
  wixCodeApi,
}: ControllerConfig) => {
  const userInstance = wixCodeApi.user.currentUser.instance;
  const appInstance = appParams.instance;
  let instance = userInstance || appInstance;

  wixCodeApi.site.onInstanceChanged((event) => {
    instance = event.instance;
  }, appParams.appDefinitionId);

  return () => instance;
};

export const initServices = (
  componentId: string,
  flowAPI: FlowAPI,
  getInstance: Handler<string>,
  experiments: WixExperiments,
): Services => {
  const useAbsoluteUrl = flowAPI.environment.isSSR;
  const useNewMembersAreaUrl = experiments.enabled(Experiments.UrlMembersArea);

  const membersApiAbsoluteUrl = useNewMembersAreaUrl
    ? newMembersApiAbsoluteBaseUrl
    : membersApiAbsoluteBaseUrl;

  const membersApiRelativeUrl = useNewMembersAreaUrl
    ? newMembersApiRelativeBaseUrl
    : membersApiRelativeBaseUrl;

  const membersServiceBaseUrl = useAbsoluteUrl
    ? membersApiAbsoluteUrl
    : membersApiRelativeUrl;

  const badgesServiceBaseUrl = useAbsoluteUrl
    ? badgesApiAbsoluteBaseUrl
    : badgesApiRelativeBaseUrl;

  return {
    membersService: new MembersService(membersServiceBaseUrl, getInstance),
    badgesService: new BadgesService(badgesServiceBaseUrl, getInstance),
    mediaService: new MediaService(),
    settingsService: new SettingsService(
      componentId,
      membersServiceBaseUrl,
      getInstance,
    ),
    blockMemberService: new BlockMemberService(getInstance),
  };
};

export const setComponentSettings = (
  store: Store,
  settings: IWixStyleParams,
) => {
  const payload = { styleParams: settings as any };
  const action = getSetComponentSettingsAction(payload);

  store.dispatch(action);
};

export const setSitePresets = (
  store: Store,
  sitePresets: SetSitePresetsPayload,
) => store.dispatch(getSetSitePresetsAction(sitePresets));

export const initProps = async ({
  currentMemberId,
  viewedMemberId,
  store,
  initialDataFetchService,
}: InitPropsOptions) => {
  const propsPromise = initialDataFetchService.fetchInitialData(
    currentMemberId,
    viewedMemberId,
  );
  const {
    currentMember,
    viewedMember,
    installedApps,
    isSocialChat,
    rolesMap,
    badgeList,
    globalSettings,
  } = await propsPromise;
  const users = {
    ...(viewedMember && { viewed: viewedMember }),
    current: currentMember,
  };

  store.dispatch(getSetUsersAction(users));
  store.dispatch(getSetBadgeListPayload(badgeList));
  store.dispatch(getSetRolesMapAction(rolesMap));
  store.dispatch(getSetIsSocialChatAction(isSocialChat));
  store.dispatch(getSetInstalledAppsAction(installedApps));
  store.dispatch(getSetGlobalSettingsAction(globalSettings));
};

export const emitAppLoadedBIEvent = ({
  widgetId,
  store,
  metaData,
  biLogger,
  flowAPI,
}: EmitAppLoadedBIEventOptions) => {
  emitBIEventWithPayload({
    state: store.getState(),
    extra: { metaData, biLogger, flowAPI },
    biEvent: BIEvent.AppLoaded,
    payload: {
      origin: PROFILE_ORIGIN,
      page_name: PROFILE_ORIGIN,
      widget_name: PROFILE_ORIGIN,
      widget_id: widgetId,
      member_id: store.getState()?.users?.viewed?.uid,
      currentPageId:
        flowAPI?.controllerConfig?.wixCodeApi?.site?.currentPage?.applicationId,
    },
  });
};

const shouldTogglePrivateProfileNotification = (
  store: Store,
  flowAPI: FlowAPI,
  experiments: WixExperiments,
) => {
  const state = store.getState();
  const { viewed } = state.users;
  const { isViewer, isSSR } = flowAPI.environment;
  const isViewerCSR = isViewer && !isSSR;

  return (
    isViewerCSR &&
    isInPage(flowAPI, aboutAppDefinitionId) &&
    getIsOwnProfile(state) &&
    !isMemberInCommunity(viewed)
  );
};

export const maybeOpenPrivateProfilePreviewNotification = ({
  store,
  flowAPI,
  experiments,
  handlers,
}: OpenProfilePreviewNotificationOptions) => {
  if (shouldTogglePrivateProfileNotification(store, flowAPI, experiments)) {
    handlers.openPrivateProfilePreviewNotification();
  }
};
